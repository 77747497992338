import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loader from "../layout/Loader";
import { getEvent } from "../../actions/event";
import FullEvent from "./FullEvent";
import Footer from "../layout/Footer";
import CommentForm from "./CommentForm";
import CommentItem from "./CommentItem";

const Event = ({ getEvent, event: { event, loading }, match }) => {
  useEffect(() => {
    getEvent(match.params.id);
  }, [getEvent, match.params.id]);

  return loading || event === null ? (
    <Loader />
  ) : (
    <Fragment>
      <section>
        <FullEvent event={event} />
        <CommentForm eventId={event._id} />
        <div className='comments-container'>
          {event.comments.map(comment => (
            <CommentItem
              key={comment._id}
              comment={comment}
              eventId={event._id}
            />
          ))}
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

Event.propTypes = {
  getEvent: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  event: state.event
});

export default connect(mapStateToProps, { getEvent })(Event);
