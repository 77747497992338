import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import { Redirect } from "react-router-dom";

const LoginForm = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

  const { email, password } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    login(email, password);
  };

  // Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <form onSubmit={e => onSubmit(e)}>
      <div className="row-field row">
        <div className="col-field col-sm-12 col-md-12 row">
          <div className="form-group col-sm-6 col-md-6">
            <input
              type="email"
              className="form-control"
              name="email"
              value={email}
              onChange={e => onChange(e)}
              required
              placeholder="Email *"
            />
          </div>
          <div className="form-group col-sm-6 col-md-6">
            <input
              type="password"
              className="form-control"
              name="password"
              value={password}
              onChange={e => onChange(e)}
              required
              placeholder="Password *"
            />
          </div>
        </div>
      </div>
      <div className="form-submit text-right">
        <button type="submit" className="btn-shadow">
          Send <i className="icon-next" />
        </button>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(LoginForm);
