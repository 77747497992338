import React from "react";
import { FadeTransform } from "react-animation-components";
import Loader from "./Loader";
import Footer from "./Footer";
import LandingInside from "./LandingInside";

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1500);
  }

  render() {
    let contentLanding = this.state.loading ? (
      <Loader />
    ) : (
      <FadeTransform
        in
        transformProps={{
          exitTransform: "translateY(-50px)"
        }}
        fadeProps={{
          enterOpacity: 1
        }}
      >
        <LandingInside />
        <Footer />
      </FadeTransform>
    );

    return <div>{contentLanding}</div>;
  }
}

export default Landing;
