import React, { Fragment, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createProfile } from "../../actions/profile";

const CreateProfile = ({ createProfile, history }) => {
  const [formData, setFormData] = useState({
    usertype: "",
    bio: "",
    skills: "",
    youtube: "",
    facebook: "",
    instagram: ""
  });

  const [displaySocialInputs, toggleSocialInputs] = useState(false);

  const { usertype, bio, skills, youtube, facebook, instagram } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    createProfile(formData, history);
  };

  return (
    <Fragment>
      <section>
        <h1>Create Your Profile</h1>
        <br />
        <form onSubmit={e => onSubmit(e)}>
          <div className="row-field row">
            <div className="col-field col-sm-12 col-md-12 row">
              <div className="form-group col-sm-12 col-md-12">
                <input
                  type="text"
                  className="form-control"
                  name="usertype"
                  value={usertype}
                  onChange={e => onChange(e)}
                  placeholder="Your Title *"
                />
              </div>
              <div className="form-group col-sm-12 col-md-12">
                <input
                  type="text"
                  className="form-control"
                  name="skills"
                  value={skills}
                  onChange={e => onChange(e)}
                  placeholder="Skills and Talents"
                />
              </div>
              <div className="form-group col-sm-12 col-md-12">
                <textarea
                  className="form-control"
                  name="bio"
                  value={bio}
                  onChange={e => onChange(e)}
                  placeholder="About You"
                />
              </div>
            </div>
          </div>
          <div className="row-field row">
            <button
              onClick={() => toggleSocialInputs(!displaySocialInputs)}
              type="button"
              className="songs-play"
            >
              Add Social Networks Links
            </button>
          </div>

          {displaySocialInputs && (
            <section className="social-input">
              <div className="form-group col-sm-12 col-md-12">
                <div className="social-icons-input">
                  <i className="fab fa-youtube"></i>
                </div>{" "}
                <div className="social-inputs">
                  <input
                    type="text"
                    className="form-control"
                    name="youtube"
                    value={youtube}
                    onChange={e => onChange(e)}
                    placeholder="YouTube"
                  />
                </div>
              </div>
              <div className="form-group col-sm-12 col-md-12">
                <div className="social-icons-input">
                  <i className="fab fa-facebook-square"></i>
                </div>{" "}
                <div className="social-inputs">
                  <input
                    type="text"
                    className="form-control"
                    name="facebook"
                    value={facebook}
                    onChange={e => onChange(e)}
                    placeholder="Facebook"
                  />
                </div>
              </div>
              <div className="form-group col-sm-12 col-md-12">
                <div className="social-icons-input">
                  <i className="fab fa-instagram"></i>
                </div>{" "}
                <div className="social-inputs">
                  <input
                    type="text"
                    className="form-control"
                    name="instagram"
                    value={instagram}
                    onChange={e => onChange(e)}
                    placeholder="Instagram"
                  />
                </div>
              </div>
            </section>
          )}

          <div className="form-submit text-right">
            <button type="submit" className="btn-shadow">
              Send <i className="icon-next" />
            </button>
            <Link to="/dashboard" className="dashboard-goback">
              Go Back
            </Link>
          </div>
        </form>
      </section>
    </Fragment>
  );
};

CreateProfile.propTypes = {
  createProfile: PropTypes.func.isRequired
};

export default connect(null, { createProfile })(withRouter(CreateProfile));
