import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loader from "../layout/Loader";
import { getProfileById } from "../../actions/profile";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProfileTop from "./ProfileTop";
import ProfileExperience from "./ProfileExperience";
import Footer from "../layout/Footer";

const Profile = ({
  getProfileById,
  profile: { profile, loading },
  auth,
  match
}) => {
  useEffect(() => {
    getProfileById(match.params.id);
  }, [getProfileById, match.params.id]);

  return (
    <Fragment>
      {profile === null || loading ? (
        <Loader />
      ) : (
        <Fragment>
          <section>
            <Row className="profiles-in">
              <Col lg="4">
                <Link to="/administrators" className="dashboard-goback">
                  Go Back
                </Link>
                {auth.isAuthenticated &&
                  auth.loading === false &&
                  auth.user._id === profile.user._id && (
                    <Link to="/edit-profile" className="dashboard-goback">
                      Edit Profile
                    </Link>
                  )}
              </Col>
              <Col lg="8"></Col>
            </Row>
            <Row className="profiles-in">
              <ProfileTop profile={profile} />
              <div className="social-in-profile">
                <h3>Experience</h3>
              </div>

              {profile.experience.length > 0 ? (
                <Fragment>
                  {profile.experience.map(experience => (
                    <ProfileExperience
                      key={experience._id}
                      experience={experience}
                    />
                  ))}
                </Fragment>
              ) : (
                <h4>No Experience Listed</h4>
              )}
            </Row>
          </section>
          <Footer />
        </Fragment>
      )}
    </Fragment>
  );
};

Profile.propTypes = {
  getProfileById: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth
});

export default connect(mapStateToProps, { getProfileById })(Profile);
