import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import { addLike, removeLike, deleteEvent } from "../../actions/event";
import { Card, Row, Col, Button } from "react-bootstrap";

const EventItem = ({
  addLike,
  removeLike,
  deleteEvent,
  auth,
  event: {
    _id,
    eventphoto,
    title,
    when,
    location,
    name,
    avatar,
    user,
    likes,
    comments,
    date
  },
  showActions
}) => {
  return (
    <Col lg='4' md='6' sm='12'>
      <Card>
        <Link to={`/events/${_id}`} className='post-like'>
          <Card.Img
            variant='top'
            src={`${process.env.REACT_APP_SERVER}/uploads/${eventphoto}`}
          />
        </Link>
        <Card.Body>
          <div className='user-in-posts'>
            <Row>
              <Col lg='4'>
                <div className='posts-avatar'>
                  <Link to={`/profile/${user}`}>
                    <img src={avatar} alt={name} style={{ width: "100%" }} />
                  </Link>
                </div>
              </Col>
              <Col lg='8'>
                <div className='posts-data'>
                  <p>Event By</p>
                  <h5>
                    <Link to={`/profile/${user}`}>{name}</Link>
                  </h5>
                  <small className='text-muted'>
                    <Moment format='MM/DD/YYYY'>{date}</Moment>
                  </small>
                </div>
              </Col>
            </Row>
          </div>
          <div className='card-title'>
            <Link to={`/events/${_id}`} className='post-like'>
              {title}
            </Link>
          </div>
          <div className='card-text'>
            <i className='fas fa-location-arrow' style={{ float: "left" }}></i>
            <h5 style={{ float: "left", marginLeft: 10 }}>{location}</h5>
            <i
              className='far fa-calendar-alt'
              style={{ float: "left", clear: "both" }}
            ></i>
            <h6 style={{ float: "left", marginLeft: 10 }}>
              <Moment format='MMMM DD, YYYY @ hh:mm A'>{when}</Moment>
            </h6>
          </div>
        </Card.Body>
        <Card.Footer>
          {showActions && (
            <Fragment>
              <button
                onClick={e => addLike(_id)}
                type='button'
                className='post-like'
              >
                <i className='far fa-heart'></i>
                <span className='counts'>{likes.length}</span>
              </button>
              <button
                onClick={e => removeLike(_id)}
                type='button'
                className='post-like'
              >
                <i className='fas fa-heart-broken'></i>
              </button>
              <Link to={`/events/${_id}`} className='post-like'>
                <i className='fas fa-comment-alt'></i>
                {comments.length > 0 && (
                  <span className='counts'>{comments.length}</span>
                )}
                <span className='read'>READ</span>
              </Link>
              {!auth.loading && user === auth.user._id && (
                <Button
                  onClick={e => deleteEvent(_id)}
                  variant='danger'
                  size='sm'
                >
                  Delete
                </Button>
              )}
            </Fragment>
          )}
        </Card.Footer>
      </Card>
    </Col>
  );
};

EventItem.defaultProps = {
  showActions: true
};

EventItem.propTypes = {
  event: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  addLike: PropTypes.func.isRequired,
  removeLike: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  showActions: PropTypes.bool
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { addLike, removeLike, deleteEvent })(
  EventItem
);
