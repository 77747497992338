import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addPost } from "../../actions/post";
import ReactQuill from "react-quill";
import { Link, Redirect } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import axios from "axios";

import "react-quill/dist/quill.snow.css";
import Footer from "../layout/Footer";

const PostForm = ({ addPost }) => {
  const [redirect, setRedirect] = useState(false);
  const [file, setFile] = useState("");
  const [postphoto, setFilename] = useState("Choose Image");
  const [uploadedFile, setUploadedFile] = useState({});
  const [formData, setFormData] = useState({
    title: "",
    postintro: "",
    text: ""
  });

  const { title, postintro, text } = formData;

  const imChange = e => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  const onChange = e => {
    setFormData({
      ...formData,
      postphoto,
      [e.target.name]: e.target.value
    });
  };

  const handleChange = e => {
    setFormData({ ...formData, text: e });
  };

  const onPress = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await axios.post('/api/upload', formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      const { newimage, filePath } = res.data;

      setUploadedFile({ newimage, filePath });
    } catch (err) {
      if (err.response.status === 500) {
        console.log("There was a problem with the server");
      } else {
        console.log(err.response.data.msg);
      }
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    addPost(formData);
    setRedirect(true);
    setFormData({
      title: "",
      postintro: "",
      text: ""
    });
  };

  if (redirect) {
    return <Redirect to='/posts' />;
  }

  /*
   * Quill modules to attach to editor
   * See https://quilljs.com/docs/modules/ for complete options
   */
  PostForm.modules = {
    toolbar: [
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" }
      ],
      ["link"],
      ["clean"]
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    }
  };
  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  PostForm.formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link"
  ];

  return (
    <Fragment>
      <section>
        <Row>
          <Col lg='12' md='12' sm='12'>
            <form onSubmit={e => onSubmit(e)}>
              <div className='row-field row'>
                <div className='col-field col-lg-4 col-md-4 col-sm-12'>
                  <div className='fi-container'>
                    <h4>Featured Image</h4>
                    <input
                      type='file'
                      className='custom-file-input'
                      name='postphoto'
                      id='customFile'
                      required
                      onChange={imChange}
                    />
                    <label className='custom-file-label' htmlFor='customFile'>
                      {postphoto}
                    </label>
                    <p style={{ marginTop: 35 }}>
                      This will the image of your post
                    </p>
                    <button
                      onClick={onPress}
                      value='Upload'
                      className='btn-shadow'
                    >
                      Upload Image <i className='icon-next' />
                    </button>
                    {uploadedFile ? (
                      <div className='row mt-5'>
                        <div className='col-md-10 m-auto'>
                          <p>{uploadedFile.postphoto}</p>
                          {uploadedFile.filePath && (
                          <div className='uploaded-image'>
                            <img
                              src={`${process.env.REACT_APP_SERVER}/uploads/${uploadedFile.filePath}`}
                              alt={uploadedFile.postphoto}
                            />
                          </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='col-field col-lg-8 col-md-8 col-sm-12'>
                  <div className='form-group'>
                    {postphoto === "Choose Image" ? (
                      <input
                        type='text'
                        className='form-control'
                        name='title'
                        value={title}
                        onChange={e => onChange(e)}
                        required
                        placeholder='Post Title * (First, upload an image)'
                        disabled
                      />
                    ) : (
                      <input
                        type='text'
                        className='form-control'
                        name='title'
                        value={title}
                        onChange={e => onChange(e)}
                        required
                        placeholder='Post Title *'
                      />
                    )}
                  </div>
                  <div className='form-group'></div>
                  <div className='form-group'>
                    {postphoto === "Choose Image" ? (
                      <input
                        type='text'
                        className='form-control'
                        name='postintro'
                        value={postintro}
                        onChange={e => onChange(e)}
                        required
                        placeholder='Post Intro * (First, upload an image)'
                        disabled
                      />
                    ) : (
                      <input
                        type='text'
                        className='form-control'
                        name='postintro'
                        value={postintro}
                        onChange={e => onChange(e)}
                        required
                        placeholder='Post Intro *'
                      />
                    )}
                  </div>
                  <div className='form-group'>
                    <ReactQuill
                      modules={PostForm.modules}
                      formats={PostForm.formats}
                      value={text}
                      placeholder='Write the body of the post here *'
                      onChange={e => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className='form-submit text-right'>
                <button type='submit' className='btn-shadow'>
                  Submit Post
                </button>
                <Link to='/posts' className='dashboard-goback'>
                  Go Back
                </Link>
              </div>
            </form>
          </Col>
        </Row>
      </section>
      <Footer />
    </Fragment>
  );
};

PostForm.propTypes = {
  addPost: PropTypes.func.isRequired
};

export default connect(null, { addPost })(PostForm);
