import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { deleteAccount } from "../../actions/profile";
import { connect } from "react-redux";

const DashboardActions = ({ deleteAccount }) => {
  return (
    <div className='dashboard-menu-block'>
      <Link to='/edit-profile' className='dashboard-menu'>
        <i className='fas fa-user-edit'></i>
        {"  "}Edit Profile
      </Link>
      <Link to='/add-experience' className='dashboard-menu'>
        <i className='fab fa-black-tie'></i>
        {"  "}Add Experience
      </Link>
      <Link to='/new-post' className='dashboard-menu'>
        <i className='fas fa-edit'></i>
        {"  "}Add New Post
      </Link>
      <Link to='/new-event' className='dashboard-menu'>
        <i className='fas fa-calendar-plus'></i>
        {"  "}Add New Event
      </Link>
      <a href='/#' onClick={() => deleteAccount()} className='dashboard-menu'>
        <i className='fas fa-user-minus'></i>
        {"  "}Delete Account
      </a>
    </div>
  );
};

DashboardActions.propTypes = {
  deleteAccount: PropTypes.func.isRequired
};

export default connect(null, { deleteAccount })(DashboardActions);
