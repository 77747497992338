import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Elements
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import NavigationBar from "./components/layout/NavigationBar";
import Landing from "./components/layout/Landing";
import Alerting from "./components/layout/Alert";
import Routes from "./components/routing/Routes";

//Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <NavigationBar />
        <section className='alts'>
          <Alerting />
        </section>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route component={Routes} />
        </Switch>
      </Router>
    </Provider>
  );
};

function Home() {
  return <Landing />;
}

export default App;
