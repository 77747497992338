import React from "react";
import Icon from "react-icons-kit";
import { twitter } from "react-icons-kit/fa/twitter";
import { facebook } from "react-icons-kit/fa/facebook";
import { instagram } from "react-icons-kit/fa/instagram";
import { Row, Col } from "react-bootstrap";

// Images
const smallLogo = require("../../resources/images/djo-logo@2x.png");
const logoPlitz = require("../../resources/images/plitz-web-and-app-development-firm-white.png");

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer-block">
        <Row>
          <Col lg={2} md={2} sm={12}>
            <img src={smallLogo} alt="Dorothy Jude Ophals" />
          </Col>
          <Col lg={8} md={8} sm={12}>
            <h5 className="footer-closing">
              <span>Content Management System for</span>

              <br />
              <a
                style={{ color: "#fff" }}
                href="https://dorothyjudeophals.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Dorothy Jude Ophals
              </a>
            </h5>
            <p>All Rights Reserved © 2019</p>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <div className="social-footer">
              <a href="#/">
                <Icon className="social-icons" size={24} icon={twitter} />
              </a>
              <a href="#/">
                <Icon className="social-icons" size={24} icon={facebook} />
              </a>
              <a href="#/">
                <Icon className="social-icons" size={24} icon={instagram} />
              </a>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: 50 }}>
          <Col lg={12} sm={12}>
            <p>
              CMS Dashboard created by{" "}
              <a
                href="https://plitz.website"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logoPlitz} alt="Plitz7" />
              </a>
            </p>
            <p>
              For support call <a href="tel:6312120707">(631) 212-0707</a>
            </p>
          </Col>
        </Row>
      </footer>
    );
  }
}

export default Footer;
