import React, { useState } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { register } from "../../actions/auth";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

const RegisterForm = ({ setAlert, register, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password2: ""
  });

  const { name, email, password, password2 } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    if (password !== password2) {
      setAlert("Passwords do not match", "danger");
    } else {
      register({ name, email, password });
    }
  };

  // if (!isAuthenticated) {
  //   return <Redirect to="/login" />;
  // }

  return (
    <form onSubmit={e => onSubmit(e)}>
      <div className="row-field row">
        <div className="col-field col-sm-12 col-md-12 row">
          <div className="form-group col-sm-6 col-md-6">
            <input
              type="text"
              className="form-control"
              name="name"
              value={name}
              onChange={e => onChange(e)}
              required
              placeholder="Name *"
            />
          </div>
          <div className="form-group col-sm-6 col-md-6">
            <input
              type="email"
              className="form-control"
              name="email"
              value={email}
              onChange={e => onChange(e)}
              required
              placeholder="Email *"
            />
          </div>
        </div>
        <div className="col-field col-sm-12 col-md-12 row">
          <div className="form-group col-sm-6 col-md-6">
            <input
              type="password"
              className="form-control"
              name="password"
              value={password}
              onChange={e => onChange(e)}
              required
              minLength="6"
              placeholder="Password *"
            />
          </div>
          <div className="form-group col-sm-6 col-md-6">
            <input
              type="password"
              className="form-control"
              name="password2"
              value={password2}
              onChange={e => onChange(e)}
              required
              minLength="6"
              placeholder="Confirm Password"
            />
          </div>
        </div>
      </div>
      <div className="form-submit text-right">
        <button type="submit" className="btn-shadow">
          Send <i className="icon-next" />
        </button>
      </div>
    </form>
  );
};

RegisterForm.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { setAlert, register })(RegisterForm);
