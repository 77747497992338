import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addEvent } from '../../actions/event';
import ReactQuill from 'react-quill';
import { Link, Redirect } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import DatePicker from 'react-datepicker';

import 'react-quill/dist/quill.snow.css';
import 'react-datepicker/dist/react-datepicker.css';
import Footer from '../layout/Footer';

const EventForm = ({ addEvent }) => {
  const [redirect, setRedirect] = useState(false);
  const [file, setFile] = useState('');
  const [postphoto, setFilename] = useState('Choose Image');
  const [uploadedFile, setUploadedFile] = useState({});
  const [formData, setFormData] = useState({
    title: '',
    when: new Date(),
    location: '',
    description: '',
  });

  const { title, when, location, description } = formData;

  const imChange = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  const onChange = (e) => {
    setFormData({
      ...formData,
      postphoto,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, description: e });
  };

  const dateChange = (e) => {
    setFormData({ ...formData, when: e });
  };

  const onPress = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);

    try {
      const res = await axios.post('/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const { postphoto, filePath } = res.data;

      setUploadedFile({ postphoto, filePath });
    } catch (err) {
      if (err.response.status === 500) {
        console.log('There was a problem with the server');
      } else {
        console.log(err.response.data.msg);
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    addEvent(formData);
    setRedirect(true);
    setFormData({
      title: '',
      when: '',
      location: '',
      description: '',
    });
  };

  if (redirect) {
    return <Redirect to='/events' />;
  }

  /*
   * Quill modules to attach to editor
   * See https://quilljs.com/docs/modules/ for complete options
   */
  EventForm.modules = {
    toolbar: [
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
      ['clean'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  EventForm.formats = [
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
  ];

  return (
    <Fragment>
      <section>
        <Row>
          <Col lg='12' md='12' sm='12'>
            <form onSubmit={(e) => onSubmit(e)}>
              <div className='row-field row'>
                <div className='col-field col-lg-4 col-md-4 col-sm-12'>
                  <div className='fi-container'>
                    <h4>Featured Image</h4>
                    <input
                      type='file'
                      className='custom-file-input'
                      name='postphoto'
                      id='customFile'
                      required
                      onChange={imChange}
                    />
                    <label className='custom-file-label' htmlFor='customFile'>
                      {postphoto}
                    </label>
                    <p style={{ marginTop: 35 }}>
                      This will the image of your event
                    </p>
                    <button
                      onClick={onPress}
                      value='Upload'
                      className='btn-shadow'
                    >
                      Upload Image <i className='icon-next' />
                    </button>
                    {uploadedFile ? (
                      <div className='row mt-5'>
                        <div className='col-md-10 m-auto'>
                          <p>{uploadedFile.postphoto}</p>
                          {uploadedFile.filePath (
                          <div className='uploaded-image'>
                            <img
                              src={`${process.env.REACT_APP_SERVER}/uploads/${uploadedFile.filePath}`}
                              alt={uploadedFile.postphoto}
                            />
                          </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='col-field col-lg-8 col-md-8 col-sm-12'>
                  <div className='form-group'>
                    {postphoto === 'Choose Image' ? (
                      <input
                        type='text'
                        className='form-control'
                        name='title'
                        value={title}
                        onChange={(e) => onChange(e)}
                        required
                        placeholder='Event Title * (First, upload an image)'
                        disabled
                      />
                    ) : (
                      <input
                        type='text'
                        className='form-control'
                        name='title'
                        value={title}
                        onChange={(e) => onChange(e)}
                        required
                        placeholder='Event Title *'
                      />
                    )}
                  </div>
                  <div className='form-group'></div>
                  <div className='form-group'>
                    <DatePicker
                      selected={when}
                      onChange={(e) => dateChange(e)}
                      showTimeSelect
                      dateFormat='MMMM d, yyyy h:mm aa'
                      className='form-control'
                    />
                  </div>
                  <div className='form-group'>
                    {postphoto === 'Choose Image' ? (
                      <input
                        type='text'
                        className='form-control'
                        name='location'
                        value={location}
                        onChange={(e) => onChange(e)}
                        required
                        placeholder='Event Location * (First, upload an image)'
                        disabled
                      />
                    ) : (
                      <input
                        type='text'
                        className='form-control'
                        name='location'
                        value={location}
                        onChange={(e) => onChange(e)}
                        required
                        placeholder='Event Location *'
                      />
                    )}
                  </div>
                  <div className='form-group'>
                    <ReactQuill
                      modules={EventForm.modules}
                      formats={EventForm.formats}
                      value={description}
                      placeholder='Write the body of the event here *'
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className='form-submit text-right'>
                <button type='submit' className='btn-shadow'>
                  Submit Event
                </button>
                <Link to='/events' className='dashboard-goback'>
                  Go Back
                </Link>
              </div>
            </form>
          </Col>
        </Row>
      </section>
      <Footer />
    </Fragment>
  );
};

EventForm.propTypes = {
  addEvent: PropTypes.func.isRequired,
};

export default connect(null, { addEvent })(EventForm);
