import React, { Fragment, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createProfile, getCurrentProfile } from '../../actions/profile';

const EditProfile = ({
  profile: { profile, loading },
  createProfile,
  getCurrentProfile,
  history,
}) => {
  const [formData, setFormData] = useState({
    usertype: '',
    bio: '',
    skills: '',
    youtube: '',
    facebook: '',
    instagram: '',
  });

  const [displaySocialInputs, toggleSocialInputs] = useState(false);

  useEffect(() => {
    getCurrentProfile();

    setFormData({
      usertype: loading || !profile.usertype ? '' : profile.usertype,
      bio: loading || !profile.bio ? '' : profile.bio,
      skills: loading || !profile.skills ? '' : profile.skills.join(','),
      youtube: loading || !profile.social ? '' : profile.social.youtube,
      facebook: loading || !profile.social ? '' : profile.social.facebook,
      instagram: loading || !profile.social ? '' : profile.social.instagram,
    });

    // eslint-disable-next-line
  }, [getCurrentProfile]);

  const { usertype, bio, skills, youtube, facebook, instagram } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    createProfile(formData, history, true);
  };

  return (
    <Fragment>
      <section>
        <h1>Create Your Profile</h1>
        <br />
        <form onSubmit={(e) => onSubmit(e)}>
          <div className='row-field row'>
            <div className='col-field col-sm-12 col-md-12 row'>
              <div className='form-group col-sm-12 col-md-12'>
                <input
                  type='text'
                  className='form-control'
                  name='usertype'
                  value={usertype}
                  onChange={(e) => onChange(e)}
                  placeholder='Your Title *'
                />
              </div>
              <div className='form-group col-sm-12 col-md-12'>
                <input
                  type='text'
                  className='form-control'
                  name='skills'
                  value={skills}
                  onChange={(e) => onChange(e)}
                  placeholder='Skills and Talents'
                />
              </div>
              <div className='form-group col-sm-12 col-md-12'>
                <textarea
                  className='form-control'
                  name='bio'
                  value={bio}
                  onChange={(e) => onChange(e)}
                  placeholder='About You'
                />
              </div>
            </div>
          </div>
          <div className='row-field row'>
            <button
              onClick={() => toggleSocialInputs(!displaySocialInputs)}
              type='button'
              className='songs-play'
            >
              Add Social Networks Links
            </button>
          </div>

          {displaySocialInputs && (
            <section className='social-input'>
              <div className='form-group col-sm-12 col-md-12'>
                <div className='social-icons-input'>
                  <i className='fab fa-youtube'></i>
                </div>{' '}
                <div className='social-inputs'>
                  <input
                    type='text'
                    className='form-control'
                    name='youtube'
                    value={youtube}
                    onChange={(e) => onChange(e)}
                    placeholder='YouTube'
                  />
                </div>
              </div>
              <div className='form-group col-sm-12 col-md-12'>
                <div className='social-icons-input'>
                  <i className='fab fa-facebook-square'></i>
                </div>{' '}
                <div className='social-inputs'>
                  <input
                    type='text'
                    className='form-control'
                    name='facebook'
                    value={facebook}
                    onChange={(e) => onChange(e)}
                    placeholder='Facebook'
                  />
                </div>
              </div>
              <div className='form-group col-sm-12 col-md-12'>
                <div className='social-icons-input'>
                  <i className='fab fa-instagram'></i>
                </div>{' '}
                <div className='social-inputs'>
                  <input
                    type='text'
                    className='form-control'
                    name='instagram'
                    value={instagram}
                    onChange={(e) => onChange(e)}
                    placeholder='Instagram'
                  />
                </div>
              </div>
            </section>
          )}

          <div className='form-submit text-right'>
            <button type='submit' className='btn-shadow'>
              Send <i className='icon-next' />
            </button>
            <Link to='/dashboard' className='dashboard-goback'>
              Go Back
            </Link>
          </div>
        </form>
      </section>
    </Fragment>
  );
};

EditProfile.propTypes = {
  createProfile: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { createProfile, getCurrentProfile })(
  withRouter(EditProfile)
);
