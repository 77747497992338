import React, { Fragment } from "react";
import axios from "axios";
import Moment from "react-moment";

class Emails extends React.Component {
  state = {
    emails: [],
    isLoaded: false
  };

  componentDidMount() {
    axios
      .get("/api/emails")
      .then(res =>
        this.setState({
          emails: res.data,
          isLoaded: true
        })
      )
      .catch(err => console.log(err));
  }

  render() {
    const { emails, isLoaded } = this.state;

    if (isLoaded) {
      return (
        <Fragment>
          <div className='right-side-dash'>
            <h3>Inbox</h3>
            {emails.map(email => (
              <div key={email._id} className='email-item'>
                <div className='email-top'>
                  <h5>
                    <b>From:</b> {email.name}
                  </h5>
                  <div className='email-phone'>
                    <a href={"tel:" + email.phone}>
                      <i className='fas fa-mobile'></i> {email.phone}
                    </a>
                  </div>
                </div>
                <ul className='email-messages'>
                  <li>
                    <p className='labels'>
                      <i className='fas fa-at'></i> email:
                    </p>
                    <a href={"mailto:" + email.email}>{email.email}</a>
                  </li>
                  <li>
                    <p className='labels'>
                      <i className='fas fa-calendar-day'></i> event date:
                    </p>
                    <Moment format='MMMM DD, YYYY | hh:mm A'>
                      {email.thedate}
                    </Moment>
                  </li>
                  <li>
                    <p className='labels'>
                      <i className='fas fa-child'></i> event type:
                    </p>
                    {email.eventtype}
                  </li>
                </ul>
                <p>{email.address}</p>
                <p>{email.details}</p>
              </div>
            ))}
          </div>
        </Fragment>
      );
    }

    return <h3>Loading Messages...</h3>;
  }
}

export default Emails;
