import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import {
  addLike,
  removeLike,
  deleteEvent,
  getEvents
} from "../../actions/event";
import { Row, Col } from "react-bootstrap";

const FullEvent = ({
  addLike,
  removeLike,
  deleteEvent,
  auth,
  getEvents,
  event: {
    _id,
    eventphoto,
    title,
    when,
    location,
    description,
    name,
    avatar,
    user,
    likes,
    comments,
    date
  }
}) => {
  useEffect(() => {
    getEvents();
  }, [getEvents]);

  return (
    <div className='post-container'>
      <div className='full-post-image'>
        <img
          src={`${process.env.REACT_APP_SERVER}/uploads/${eventphoto}`}
          alt={title}
        />
      </div>
      <div className='full-post-intro'>
        <div className='user-in-posts'>
          <Row>
            <Col lg='4'>
              <div className='posts-avatar'>
                <Link to={`/profile/${user}`}>
                  <img src={avatar} alt={name} style={{ width: "100%" }} />
                </Link>
              </div>
            </Col>
            <Col lg='8'>
              <div className='posts-data'>
                <p>Event By</p>
                <h5>
                  <Link to={`/profile/${user}`}>{name}</Link>
                </h5>
                <small className='text-muted'>
                  <Moment format='MM/DD/YYYY'>{date}</Moment>
                </small>
                <div className='full-post-interaction'>
                  <button
                    onClick={e => addLike(_id, window.location.reload(false))}
                    type='button'
                    className='post-like'
                  >
                    <i className='far fa-heart'></i>
                    <span className='counts'>{likes.length}</span>
                  </button>
                  <button
                    onClick={e =>
                      removeLike(_id, window.location.reload(false))
                    }
                    type='button'
                    className='post-like'
                  >
                    <i className='fas fa-heart-broken'></i>
                  </button>
                  <i className='fas fa-comment-alt'></i>
                  {comments.length > 0 && (
                    <span className='counts'>{comments.length}</span>
                  )}
                </div>
                <Link to='/events' className='dashboard-goback'>
                  Back to Events
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        <div className='card-title'>{title}</div>
        <div className='card-text'>
          <div className='event-info'>
            <i className='fas fa-location-arrow' style={{ float: "left" }}></i>
            <h5 style={{ float: "left", marginLeft: 10 }}>{location}</h5>
            <i
              className='far fa-calendar-alt'
              style={{ float: "left", marginLeft: 30 }}
            ></i>
            <h5 style={{ float: "left", marginLeft: 10 }}>
              <Moment format='MMMM DD, YYYY | hh:mm A'>{when}</Moment>
            </h5>
          </div>
          <div className='full-post-body'>
            <h3>What is the event about</h3>
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
          </div>
        </div>
        {!auth.loading && user === auth.user._id && (
          <Link
            to='/events'
            className='dashboard-delete-red'
            style={{ float: "right" }}
            onClick={e => deleteEvent(_id)}
            variant='danger'
            size='sm'
          >
            Delete This Event
          </Link>
        )}
      </div>
    </div>
  );
};

FullEvent.propTypes = {
  getEvents: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  addLike: PropTypes.func.isRequired,
  removeLike: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  addLike,
  removeLike,
  deleteEvent,
  getEvents
})(FullEvent);
