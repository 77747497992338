import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loader from "../layout/Loader";
import { getEvents } from "../../actions/event";
import { Row, Col } from "react-bootstrap";
import EventItem from "./EventItem";
import Footer from "../layout/Footer";
import { Link } from "react-router-dom";

const Events = ({ getEvents, event: { events, loading } }) => {
  useEffect(() => {
    getEvents();
  }, [getEvents]);

  return loading ? (
    <Loader />
  ) : (
    <Fragment>
      <section>
        <Row className="title-happenings">
          <Col>
            <h1>Happenings</h1>
            <p>What Dorothy is writing and her next events.</p>
            <Link
              to="/new-event"
              className="btn-shadow"
              style={{ float: "right" }}
            >
              Add New Event
            </Link>
          </Col>
        </Row>
        <Row className="all-happenings">
          {events.map(event => (
            <EventItem key={event._id} event={event} />
          ))}
        </Row>
      </section>
      <Footer />
    </Fragment>
  );
};

Events.propTypes = {
  getEvents: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  event: state.event
});

export default connect(mapStateToProps, { getEvents })(Events);
