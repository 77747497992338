import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";

const ProfileItem = ({
  profile: {
    user: { _id, name, avatar },
    usertype,
    bio,
    skills
  }
}) => {
  return (
    <Fragment>
      <section>
        <Row className="profiles-items">
          <Col lg="3">
            <div className="profile-avatar">
              <img src={avatar} alt={name} />
            </div>
          </Col>
          <Col lg="6">
            <h3>{name}</h3>
            <h5>{usertype}</h5>
            <p>{bio}</p>
          </Col>
          <Col lg="3">
            <ul>
              {skills.slice(0, 4).map((skill, index) => (
                <li className="skills-display" key={index}>
                  <i className="fas fa-check"></i> {skill}
                </li>
              ))}
            </ul>
            <Link to={`/profile/${_id}`} className="dashboard-menu">
              {"  "}View Profile
            </Link>
          </Col>
        </Row>
      </section>
    </Fragment>
  );
};

ProfileItem.propTypes = {
  profile: PropTypes.object.isRequired
};

export default ProfileItem;
