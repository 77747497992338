import React, { Fragment, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCurrentProfile } from "../../actions/profile";
import Loader from "../layout/Loader";
import { Link } from "react-router-dom";

// Parts
import Footer from "../layout/Footer";
import DashboardActions from "./DashboardActions";
import Experience from "./Experience";
import Emails from "./Emails";

const Dashboard = ({
  getCurrentProfile,
  auth: { user },
  profile: { profile, loading }
}) => {
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);

  return loading && profile === null ? (
    <Loader />
  ) : (
    <Fragment>
      <section>
        <Row>
          <Col>
            <p>Welcome to your Dashboard</p>
            <h1>{user && user.name}</h1>
          </Col>
        </Row>
        {profile !== null ? (
          <Fragment>
            <section>
              <Row>
                <Col lg="3" md="3" sm="12">
                  <DashboardActions />
                </Col>
                <Col lg="9" md="9" sm="12">
                  <Emails />
                  <Experience experience={profile.experience} />
                </Col>
              </Row>
            </section>
          </Fragment>
        ) : (
          <Fragment>
            <section>
              <Row>
                <Col>
                  <p>You have not yet setup a profile, please add some info</p>
                  <Button variant="warning">
                    <Link style={{ color: "#fff" }} to="/create-profile">
                      Create Profile
                    </Link>
                  </Button>
                </Col>
              </Row>
            </section>
          </Fragment>
        )}
      </section>
      <Footer />
    </Fragment>
  );
};

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(mapStateToProps, { getCurrentProfile })(Dashboard);
