import React from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";

const ProfileExperience = ({
  experience: { institution, title, location, current, to, from, description }
}) => {
  return (
    <div className="exp-in-profile">
      <h5>{institution}</h5>
      <h3>{title}</h3>
      <p>
        <Moment format="MM/DD/YYYY">{from}</Moment> -{" "}
        {!to ? "Now" : <Moment format="MM/DD/YYYY">{to}</Moment>}
      </p>
      <p>
        <i className="fas fa-street-view"></i> {location}
      </p>
      <div className="exp-ip-desc">
        <h4>Description: </h4>
        <p>{description}</p>
      </div>
    </div>
  );
};

ProfileExperience.propTypes = {
  experience: PropTypes.object.isRequired
};

export default ProfileExperience;
