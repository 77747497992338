import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addComment } from "../../actions/post";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

const CommentForm = ({ postId, addComment }) => {
  const [formData, setFormData] = useState({
    text: ""
  });

  const { text } = formData;

  const handleChange = e => {
    setFormData({ ...formData, text: e });
  };

  const onSubmit = e => {
    e.preventDefault();
    addComment(postId, formData);
    setFormData({
      text: ""
    });
  };

  /*
   * Quill modules to attach to editor
   * See https://quilljs.com/docs/modules/ for complete options
   */
  CommentForm.modules = {
    toolbar: [
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" }
      ],
      ["link"],
      ["clean"]
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    }
  };
  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  CommentForm.formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link"
  ];

  return (
    <div className="comments-container">
      <h3>Leave a comment</h3>
      <form onSubmit={e => onSubmit(e)}>
        <div className="row-field row">
          <div className="col-field col-sm-12 col-md-12 row">
            <div className="form-group">
              <ReactQuill
                modules={CommentForm.modules}
                formats={CommentForm.formats}
                value={text}
                placeholder="Leave a comment"
                onChange={e => handleChange(e)}
              />
            </div>
          </div>
        </div>
        <div className="form-submit text-right">
          <button type="submit" className="btn-shadow">
            Send <i className="icon-next" />
          </button>
        </div>
      </form>
    </div>
  );
};

CommentForm.propTypes = {
  addComment: PropTypes.func.isRequired
};

export default connect(null, { addComment })(CommentForm);
