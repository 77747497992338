import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import Moment from "react-moment";
import { deleteComment } from "../../actions/post";

const CommentItem = ({
  postId,
  comment: { _id, text, name, avatar, user, date },
  auth,
  deleteComment
}) => {
  return (
    <div className="post-container">
      <div className="posts-block-comment">
        <div className="user-in-posts">
          <Row>
            <Col lg="2">
              <div className="posts-avatar-comment">
                <Link to={`/profile/${user}`}>
                  <img src={avatar} alt={name} style={{ width: "100%" }} />
                </Link>
              </div>
            </Col>
            <Col lg="10">
              <div className="posts-data-comment">
                <p>Comment By</p>
                <h5>
                  <Link to={`/profile/${user}`}>{name}</Link>
                </h5>
                <small className="text-muted">
                  <Moment format="MM/DD/YYYY">{date}</Moment>
                </small>
              </div>
            </Col>
          </Row>
        </div>

        <div className="card-text">
          <div className="full-post-body">
            <div dangerouslySetInnerHTML={{ __html: text }}></div>
          </div>
        </div>
        {!auth.loading && user === auth.user._id && (
          <Button
            style={{ float: "right" }}
            onClick={e => deleteComment(postId, _id)}
            variant="danger"
            size="sm"
          >
            Delete
          </Button>
        )}
      </div>
    </div>
  );
};

CommentItem.propTypes = {
  postId: PropTypes.string.isRequired,
  comment: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  deleteComment: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { deleteComment })(CommentItem);
