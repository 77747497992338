import React, { Fragment, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addExperience } from "../../actions/profile";

const AddExperience = ({ addExperience, history }) => {
  const [formData, setFormData] = useState({
    institution: "",
    title: "",
    location: "",
    from: "",
    to: "",
    current: false,
    description: ""
  });

  const [toDateDisabled, toggleDisabled] = useState(false);

  const {
    institution,
    title,
    location,
    from,
    to,
    current,
    description
  } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <Fragment>
      <section>
        <h1>Add Experience</h1>
        <p>
          Add any musical, ministerial or professional position that you have
          had in the past
        </p>
        <form
          onSubmit={e => {
            e.preventDefault();
            addExperience(formData, history);
          }}
        >
          <div className="row-field row">
            <div className="col-field col-sm-12 col-md-12 row">
              <div className="form-group col-sm-4 col-md-4">
                <input
                  type="text"
                  className="form-control"
                  name="institution"
                  value={institution}
                  onChange={e => onChange(e)}
                  placeholder="Company, Organization or Institution *"
                />
              </div>
              <div className="form-group col-sm-4 col-md-4">
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  value={title}
                  onChange={e => onChange(e)}
                  placeholder="Title *"
                />
              </div>
              <div className="form-group col-sm-4 col-md-4">
                <input
                  type="text"
                  className="form-control"
                  name="location"
                  value={location}
                  onChange={e => onChange(e)}
                  placeholder="Location *"
                />
              </div>
            </div>
          </div>
          <div className="row-field row">
            <div className="col-field col-sm-12 col-md-12 row">
              <div className="form-group col-sm-6 col-md-6">
                <input
                  type="date"
                  className="form-control"
                  name="from"
                  value={from}
                  onChange={e => onChange(e)}
                />
              </div>
              <div className="form-group col-sm-6 col-md-6">
                <p>
                  <input
                    type="date"
                    className="form-control"
                    name="to"
                    value={to}
                    onChange={e => onChange(e)}
                    disabled={toDateDisabled ? "disabled" : ""}
                  />
                </p>
                <p>
                  <input
                    type="checkbox"
                    name="current"
                    checked={current}
                    value={current}
                    onChange={e => {
                      setFormData({ ...formData, current: !current });
                      toggleDisabled(!toDateDisabled);
                    }}
                  />{" "}
                  Current Job
                </p>
              </div>
            </div>
          </div>
          <div className="row-field row">
            <div className="col-field col-sm-12 col-md-12 row">
              <div className="form-group col-sm-12 col-md-12">
                <textarea
                  className="form-control"
                  rows="5"
                  name="description"
                  value={description}
                  onChange={e => onChange(e)}
                  placeholder="Job description"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="form-submit text-right">
            <button type="submit" className="btn-shadow">
              Send <i className="icon-next" />
            </button>
            <Link to="/dashboard" className="dashboard-goback">
              Go Back
            </Link>
          </div>
        </form>
      </section>
    </Fragment>
  );
};

AddExperience.propTypes = {
  addExperience: PropTypes.func.isRequired
};

export default connect(null, { addExperience })(withRouter(AddExperience));
