import React from "react";
import { FadeTransform } from "react-animation-components";
import { Link } from "react-router-dom";

// Page elements
import Loader from "../layout/Loader";
import LoginForm from "./LoginForm";
import Footer from "../layout/Footer";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1500);
  }

  render() {
    let contentLogin = this.state.loading ? (
      <Loader />
    ) : (
      <FadeTransform
        in
        transformProps={{
          exitTransform: "translateY(-50px)"
        }}
        fadeProps={{
          enterOpacity: 1
        }}
      >
        <section>
          <p>Input your information to login</p>
          <h1>Users Log-in</h1>
        </section>
        <section>
          <LoginForm />
        </section>
        <section style={{ textAlign: "center" }}>
          <p>
            Would you like to create a new user?
            <br />
            You must be logged in. Only administrators can add new users.
          </p>
          <button type='submit' className='btn-shadow'>
            <Link to='/register' style={{ color: "#fff" }}>
              Register <i className='fas fa-chevron-right'></i>
            </Link>
          </button>
        </section>
        <Footer />
      </FadeTransform>
    );

    return <div>{contentLogin}</div>;
  }
}

export default Login;
