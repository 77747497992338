import React from "react";
import { Row, Col, ButtonToolbar, Button } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const LandingInside = ({ isAuthenticated }) => {
  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <section>
      <Row className="home-hero">
        <Col>
          <div className="welcome-home">
            <h2 className="subtitle-home">Welcome to</h2>
            <h1 className="title-home">
              Dorothy <span>Jude</span> Ophals
            </h1>
            <p className="caption-home">Content Management System</p>
            <ButtonToolbar>
              <Button variant="primary" size="lg">
                <Link style={{ color: "#FFF" }} to="/login">
                  Login
                </Link>
              </Button>
            </ButtonToolbar>
          </div>
        </Col>
      </Row>
    </section>
  );
};

LandingInside.propTypes = {
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(LandingInside);
