import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loader from "../layout/Loader";
import { getPosts } from "../../actions/post";
import { Row, Col } from "react-bootstrap";
import PostItem from "./PostItem";
import Footer from "../layout/Footer";
import { Link } from "react-router-dom";

const Posts = ({ getPosts, post: { posts, loading } }) => {
  useEffect(() => {
    getPosts();
  }, [getPosts]);

  return loading ? (
    <Loader />
  ) : (
    <Fragment>
      <section>
        <Row className="title-happenings">
          <Col>
            <h1>Happenings</h1>
            <p>What Dorothy is writing and her next events.</p>
            <Link
              to="/new-post"
              className="btn-shadow"
              style={{ float: "right" }}
            >
              Add New Post
            </Link>
          </Col>
        </Row>
        <Row className="all-happenings">
          {posts.map(post => (
            <PostItem key={post._id} post={post} />
          ))}
        </Row>
      </section>
      <Footer />
    </Fragment>
  );
};

Posts.propTypes = {
  getPosts: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  post: state.post
});

export default connect(mapStateToProps, { getPosts })(Posts);
