import React, { Fragment } from "react";
import { Col } from "react-bootstrap";

const ProfileTop = ({
  profile: {
    usertype,
    bio,
    skills,
    social,
    user: { name, avatar }
  }
}) => {
  return (
    <Fragment>
      <Col lg="4" md="4" sm="4">
        <div className="profile-avatar">
          <img src={avatar} alt={name} />
        </div>
      </Col>
      <Col lg="8" md="8" sm="8">
        <h1>{name}</h1>
        <h4>{usertype}</h4>
        <p className="bio-in-profile">{bio}</p>
        <div className="social-in-profile">
          <h3>Skill Set</h3>
        </div>
        <div className="skills-block-in-profile">
          {skills.slice(0, 4).map((skill, index) => (
            <div className="skills-in-profile" key={index}>
              <i className="fas fa-check"></i> {skill}
            </div>
          ))}
        </div>
        <div className="social-in-profile">
          <h3>Social Profiles</h3>
        </div>
        <div className="social-in-profile-a">
          {social && social.youtube && (
            <a href={social.youtube} target="_blank" rel="noopener noreferrer">
              <div className="social-icons-input">
                <i className="fab fa-youtube"></i>
              </div>{" "}
            </a>
          )}
          {social && social.facebook && (
            <a href={social.facebook} target="_blank" rel="noopener noreferrer">
              <div className="social-icons-input">
                <i className="fab fa-facebook-square"></i>
              </div>{" "}
            </a>
          )}
          {social && social.instagram && (
            <a
              href={social.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="social-icons-input">
                <i className="fab fa-instagram"></i>
              </div>{" "}
            </a>
          )}
        </div>
      </Col>
    </Fragment>
  );
};

export default ProfileTop;
