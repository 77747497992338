import React from "react";
import { Route, Switch } from "react-router-dom";

import Dashboard from "../dashboard/Dashboard";
import PrivateRoute from "../routing/PrivateRoute";
import CreateProfile from "../profile-form/CreateProfile";
import EditProfile from "../profile-form/EditProfile";
import Profiles from "../profiles/Profiles";
import AddExperience from "../profile-form/AddExperience";
import Profile from "../profile/Profile";
import Posts from "../posts/Posts";
import Post from "../post/Post";
import PostFom from "../posts/PostForm";
import Events from "../events/Events";
import Event from "../event/Event";
import EventFom from "../events/EventForm";
import NotFound from "../layout/NotFound";
import Login from "../auth/Login";
import Register from "../auth/Register";

const Routes = () => {
  return (
    <div>
      <Switch>
        <Route exact path='/login' component={Login} />
        <Route exact path='/register' component={Register} />
        <Route exact path='/administrators' component={Profiles} />
        <Route exact path='/profile/:id' component={Profile} />
        <PrivateRoute exact path='/dashboard' component={Dashboard} />
        <PrivateRoute exact path='/create-profile' component={CreateProfile} />
        <PrivateRoute exact path='/edit-profile' component={EditProfile} />
        <PrivateRoute exact path='/add-experience' component={AddExperience} />
        <PrivateRoute exact path='/posts' component={Posts} />
        <PrivateRoute exact path='/posts/:id' component={Post} />
        <PrivateRoute exact path='/new-post' component={PostFom} />
        <PrivateRoute exact path='/events' component={Events} />
        <PrivateRoute exact path='/events/:id' component={Event} />
        <PrivateRoute exact path='/new-event' component={EventFom} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default Routes;
