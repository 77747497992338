import React, { Fragment } from "react";
import Footer from "./Footer";

const NotFound = () => {
  return (
    <Fragment>
      <section>
        <h1 className='x-large text-primary'>
          <i className='fas fa-exclamation-triangle'></i> Page Not Found
        </h1>
        <p className='large'>Sorry, this page does not exist</p>
      </section>
      <Footer />
    </Fragment>
  );
};

export default NotFound;
