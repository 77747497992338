import React, { Fragment, useEffect } from "react";
import { Navbar, Nav, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import { getCurrentProfile } from "../../actions/profile";

// Logo Image
const logo = require("../../resources/images/dorothy-ophals-logo.png");

const NavigationBar = ({
  auth: { isAuthenticated, loading, user },
  logout,
  profile: { profile },
  getCurrentProfile
}) => {
  const authLinks = (
    <Fragment>
      <div className='menu-items'>
        <NavLink to='/administrators' activeClassName='active'>
          <i className='fas fa-users'></i>
          <span className='menu-item-hide'>Admins</span>
        </NavLink>
      </div>
      <div className='menu-items'>
        <NavLink to='/dashboard' activeClassName='active'>
          <i className='fas fa-tachometer-alt'></i>{" "}
          <span className='menu-item-hide'>Dashboard</span>
        </NavLink>
      </div>
      <div className='menu-items'>
        <NavLink to='/register' activeClassName='active'>
          <i className='fas fa-user-plus'></i>{" "}
          <span className='menu-item-hide'>New User</span>
        </NavLink>
      </div>
      <div className='menu-items'>
        <NavLink to='/posts' activeClassName='active'>
          <i className='fas fa-folder'></i>{" "}
          <span className='menu-item-hide'>Posts</span>
        </NavLink>
      </div>
      <div className='menu-items'>
        <NavLink to='/events' activeClassName='active'>
          <i className='fas fa-calendar'></i>{" "}
          <span className='menu-item-hide'>Events</span>
        </NavLink>
      </div>
      <div className='menu-items'>
        <NavLink to='#!' onClick={logout}>
          <i className='fas fa-sign-out-alt'></i>{" "}
          <span className='menu-item-hide'>Logout</span>
        </NavLink>
      </div>
    </Fragment>
  );

  const guestLinks = (
    <Fragment>
      <div className='menu-items'>
        <NavLink to='/administrators' activeClassName='active'>
          Administrators
        </NavLink>
      </div>
      <div className='menu-items'>
        <NavLink to='/login' activeClassName='active'>
          Login
        </NavLink>
      </div>
    </Fragment>
  );

  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);

  return (
    <Row>
      <Col lg='2' md='12' sm='12' className='col-logo'>
        <div className='logo-class'>
          <a href='/'>
            <img src={logo} alt='Dorothy Ophals' />
          </a>
        </div>
      </Col>
      <Col lg='8' md='6' sm='6' className='col-menu'>
        <Navbar expand='lg' variant='light'>
          <Navbar id='responsive-navbar-nav'>
            <Nav className='mr-auto'>
              {!loading && (
                <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
              )}
            </Nav>
          </Navbar>
        </Navbar>
      </Col>
      <Col lg='2' md='6' sm='6' className='col-player'>
        {isAuthenticated ? (
          <div className='profile-avatar-top'>
            <img src={user && user.avatar} alt={user && user.name} />
          </div>
        ) : (
          <p></p>
        )}
      </Col>
    </Row>
  );
};

NavigationBar.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(mapStateToProps, { logout, getCurrentProfile })(
  NavigationBar
);
