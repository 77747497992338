import React from "react";
import { FadeTransform } from "react-animation-components";
import Loader from "../layout/Loader";

// Parts
import RegisterForm from "./RegisterForm";
import Footer from "../layout/Footer";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1500);
  }

  render() {
    let contentRegister = this.state.loading ? (
      <Loader />
    ) : (
      <FadeTransform
        in
        transformProps={{
          exitTransform: "translateY(-50px)"
        }}
        fadeProps={{
          enterOpacity: 1
        }}
      >
        <section>
          <p>Register form for admin users</p>
          <h1>Register a Web Admin</h1>
        </section>
        <section>
          <RegisterForm />
        </section>
        <section style={{ textAlign: "center" }}>
          You can see this page because you are signed in!
        </section>
        <Footer />
      </FadeTransform>
    );

    return <div>{contentRegister}</div>;
  }
}

export default Register;
